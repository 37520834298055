.tejik-status-selection {
	.tejik-status-selection-button {
		display: grid;
		grid-template-columns: 32px 1fr 24px;
		align-items: center;
		margin-bottom: 0.25em;
		padding: 0.25em;
		cursor: pointer;

		&:hover {
			background-color: $hover-color;
		}

		.tejik-status-selection-color {
			width: 20px;
			height: 20px;
			margin-right: 0.5em;
			border-radius: 20px;
		}

		.tejik-status-selection-text {
			display: flex;	
			flex-grow: 1;
		}
	}
}