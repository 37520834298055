@import 'styles/_variables.scss';

.content-links-container {
	padding: 0.5em;

	.info-icon {
		color: $neutral-3;
		margin-right: 0.5em;
	}

	.info-message {
		color: $neutral-3;
		display: flex;
		align-items: center;
		font-size: 14px;
		margin-top: 0.5em;
	}

	.content-link {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		padding: 1em;
		border: 1px solid $neutral-1;
		border-radius: 10px;
		height: 60px;

		.content-name {
			display: flex;
			flex-grow: 1;
			padding-left: 0.5em;
		}
	}

	.content-link-types {
		.tejik-button {
			color: $neutral-3;
		}

		.tejik-button.selected {
			color: $neutral-6;
			border: 1px solid $neutral-6;
		}
	}
}

.new-content-link-popup {
	width: 600px;

	@media (max-width: 790px) {
		width: 320px;
	}
}