@import 'styles/_variables.scss';

.p-1 {
	padding: 1rem;
}

.pb-1 {
	padding-bottom: 1rem;
}

.py-1 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.px-1 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.m-1 {
	margin: 1rem;
}

.mt-1 {
	margin-top: 1rem;
}

.my-1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.ml-1 {
	margin-left: 1rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mr-05 {
	margin-right: 0.5rem;
}

.mr-1 {
	margin-right: 1rem;
}

.plain-text {
	text-decoration: none;
}

.d-flex {
	display: flex;
}

.align-items-end {
	align-items: flex-end;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.text-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	line-height: 1.2;
}

.container-save {
	display: flex;
	justify-content: flex-end;

	> .tejik-button {
		max-width: 160px;
		width: 100%;
	}
}

.pointer {
	cursor: pointer;
}

.container-description { 
	color: $neutral-4;
	font-size: 16px;
}

.show-desktop-only {
	@media (max-width: 768px) {
		display: none!important;
	}
}

.show-mobile-only {
	display: none!important;

	@media (max-width: 790px) {
		display: initial!important;
	}
}

.show-mobile-only-flex {
	display: none!important;

	@media (max-width: 790px) {
		display: flex!important;
	}
}

.update-available {
	border-radius: 20px; 
	background-color: #E56B6F;
	color: #FFFFFF;
	height: 30px;
	left: 0; 
	right: 0; 
	margin-left: auto; 
	margin-right: auto; 
	width: fit-content;
	position: absolute;
	padding-left: 12px;
	padding-right: 12px; 
	padding-top: 8px;
}

// Draggable list css
@import 'styles/_variables.scss';

.draggable-list {
	width: 100%;
	
	> div:first-child {
		margin: 0.5em 0 0.5em 0;
	}

	.draggable-item {
		display: grid;
		grid-template-columns: 20px 1fr 20px 20px;
		padding: 0px 8px;
		border-bottom: 1px solid $neutral-1;
		align-items: center;

		&:first-of-type {
			border-top: 1px solid $neutral-1;
		}
	}
}

// Steps CSS
.steps-container {
	display: grid;
	grid-template-columns: 32px 1fr;
	gap: 32px;

	.tejik-title {
		text-align: right;
		font-weight: bold;
		color: $neutral-5;
	}
	
	.step {
		margin-bottom: 0.5em;
	}
}

.tejik-selection {
	.tejik-selection-button {
		&:not(.active) {
			position: relative;

			&::after {
				content: 'add_box';
				font-family: 'Material Icons';
				font-weight: normal;
				font-style: normal;
				font-size: inherit;
				display: inline-block;
				line-height: 1;
				text-transform: none;
				letter-spacing: normal;
				word-wrap: normal;
				white-space: nowrap;
				direction: ltr;

				/* Support for all WebKit browsers. */
				-webkit-font-smoothing: antialiased;
				/* Support for Safari and Chrome. */
				text-rendering: optimizeLegibility;

				/* Support for Firefox. */
				-moz-osx-font-smoothing: grayscale;

				/* Support for IE. */
				font-feature-settings: 'liga';

				position: absolute;
				bottom: 4px;
				right: 2px;
				font-size: 14px;
				color: $neutral-6;
			}
		}
	}
}
