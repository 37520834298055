@import 'styles/_variables.scss';

$maxItemsOptions: 4, 5, 6, 7, 8, 9, 10, 11, 12;
$itemHeight: 28px;
$itemMargin: 12px;

.tejik-menu {
	overflow-y: auto;
	border-radius: 10px;

	@each $option in $maxItemsOptions {
		&.tejik-menu-max-#{$option} {
			max-height: ($option * $itemHeight) + (($option - 1) * $itemMargin);
		}
	}
}

.tejik-menu-item {
	list-style-type: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 4px 12px;
	margin-bottom: 12px;
	color: $neutral-6;
	white-space: nowrap;

	// Nested Menu
	> .tejik-menu {
		position: relative;
		margin-top: 12px;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: -20px;
			background-color: $neutral-2;
			width: 1px;
			height: calc(100% - 14px);
		}

		> .tejik-menu-item {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: -20px;
				background-color: $neutral-2;
				width: 20px;
				height: 1px;
			}
		}
	}

	& .tejik-menu-item-description {
		padding-left: 0.5rem;
		padding-top: 0.5rem;
		font-size: 12px;
		color: $neutral-3;
	}

	&.wrap-text {
		white-space: normal;
	}
	
	&.tejik-menu-item-icon.tejik-menu-item-state {
		grid-template-columns: 30px 1fr 30px;
	}

	&.tejik-menu-item-icon {
		grid-template-columns: 30px 1fr;
	}

	&.tejik-menu-item-state {
		grid-template-columns: 1fr 30px;
	}

	&.active {
		color: $neutral-0;
	}

	&.disabled {
		color: $neutral-2;
		cursor: not-allowed;
	}

	&.selected {
		color: $neutral-2;
	}

	&.header {
		border-bottom: 1px solid $neutral-2;
		padding-bottom: 12px;
		margin-bottom: 6px;
	}

	&.footer {
		border-top: 1px solid $neutral-2;
		padding-top: 12px;
		margin-top: 6px;
	}

	.tejik-menu-item-icon {
		font-size: 20px;
		margin-right: 16px;
	}

	.tejik-menu-item-container {
		align-items: center;
		display: flex;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
		padding-left: 0.5em;
	}

	.tejik-menu-item-state {
		font-size: 20px;
		margin-right: -0.5em;
	}

	> a,
	> span.tejik-menu-item-text > button {
		width: 100%;
		text-decoration: none;
	}

	.tejik-menu-item-text {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
	}
	
	&.tejik-menu-item-action {
		cursor: pointer;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

.tejik-side-item {
	display: grid;
	font-size: 16px;
	align-items: center;
	padding: 1em;
	color: $neutral-3;
	white-space: nowrap;

	&:hover {
		color: $neutral-6;
	}

	// Nested Menu
	> .tejik-menu {
		position: relative;
		margin-top: 12px;
		grid-column: 2;
		
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: -20px;
			background-color: $neutral-2;
			width: 1px;
			height: calc(100% - 14px);
		}

		> .tejik-menu-item {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: -20px;
				background-color: $neutral-2;
				width: 20px;
				height: 1px;
			}
		}
	}

	&.tejik-menu-item-description {
		padding-left: 2rem;
		font-size: 14px;
		color: $neutral-4;
	}

	&.wrap-text {
		white-space: normal;
	}
	
	&.tejik-menu-item-icon.tejik-menu-item-state {
		grid-template-columns: 30px 1fr 30px;
	}

	&.tejik-menu-item-icon {
		grid-template-columns: 30px 1fr;
	}

	&.tejik-menu-item-state {
		grid-template-columns: 1fr 30px;
	}

	&.active {
		color: $neutral-0;
		border-radius: 10px;
		padding: 1em;
		background-color: $ternary;
	}

	&.disabled {
		color: $neutral-2;
		cursor: not-allowed;
	}

	&.selected {
		color: $neutral-2;
	}

	&.header {
		border-bottom: 1px solid $neutral-2;
		padding-bottom: 12px;
		margin-bottom: 6px;
	}

	&.footer {
		border-top: 1px solid $neutral-2;
		padding-top: 12px;
		margin-top: 6px;
	}

	> span.tejik-menu-item-icon,
	> svg.tejik-menu-item-icon {
		font-size: 15px;
		margin-right: 16px;
	}

	> span.tejik-menu-item-state,
	> svg.tejik-menu-item-state {
		font-size: 16px;
		margin-left: 16px;
	}

	> a,
	> span.tejik-menu-item-text > button {
		width: 100%;
		text-decoration: none;
	}

	&.tejik-menu-item-action {
		cursor: pointer;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
}

.tejik-side-subitem {
	display: grid;
	align-items: center;
	padding: 0.5em 0 1em 2em;
	color: $neutral-3;
	white-space: nowrap;

	&:hover {
		color: $neutral-6;
	}

	// Nested Menu
	> .tejik-menu {
		position: relative;
		margin-top: 4px;
		grid-column: 2;
		
		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 40px;
			background-color: $neutral-2;
			width: 1px;
			height: calc(100% - 14px);
		}

		> .tejik-menu-item {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 20%;
				transform: translateY(-50%);
				left: 40px;
				background-color: $neutral-2;
				width: 20px;
				height: 1px;
			}
		}
	}

	& .tejik-menu-item-description {
		padding-left: 2rem;
		font-size: 16px;
		color: $neutral-4;
	}

	&.wrap-text {
		white-space: normal;
	}
	
	&.tejik-menu-item-icon.tejik-menu-item-state {
		grid-template-columns: 30px 1fr 30px;
	}

	&.tejik-menu-item-icon {
		grid-template-columns: 30px 1fr;
	}

	&.tejik-menu-item-state {
		grid-template-columns: 1fr 30px;
	}

	&.active {
		color: $primary;
		border-radius: 10px;
	}

	&.selected {
		color: $primary;
	}

	> span.tejik-menu-item-icon,
	> svg.tejik-menu-item-icon {
		font-size: 15px;
		margin-right: 16px;
	}

	> span.tejik-menu-item-state,
	> svg.tejik-menu-item-state {
		font-size: 16px;
		margin-left: 16px;
	}

	> a,
	> span.tejik-menu-item-text > button {
		width: 100%;
		text-decoration: none;
	}

	&.tejik-menu-item-action {
		cursor: pointer;
	}
}