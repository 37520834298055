@import 'styles/_variables.scss';

.tejik-weekday-selector {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;

	.tejik-weekday-button {
		height: 30px;
		width: 30px;
		border-radius: 30px;
		
		align-self: center;
		border-style: none;
		margin-right: 1.5em;
		font-size: 14px;
		cursor: pointer;

		background-color: $hover;
		color: $neutral-6;

		@media (max-width: 790px) {
			margin-right: 0.75em;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.last {
		margin-right: 0;
	}
	
	.tejik-weekday-button-active {
		background-color: $ternary;
		color: $neutral-0;
	}
}