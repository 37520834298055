@import 'styles/_variables.scss';

.campaign-content-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 0.5em;
	margin-bottom: 2em;

	&.single-column {
		grid-template-columns: 1fr;
	}

	@media (max-width: 790px) {
		margin-bottom: -1em;
		grid-template-columns: 1fr;
	}

	.campaign-content-link {
		display: grid;
		grid-template-columns: 32px 1fr 16px;
		align-items: center;
		width: 100%;
		padding: 0.75em 1em;
		margin-bottom: 0.5em;
		border: 1px solid $neutral-1;
		border-radius: 10px;

		.campaign-content-link-content {
			display: flex;
			flex-direction: column;
			overflow: hidden;
			line-height: 1.2;
	
			.campaign-content-link-name, .campaign-content-link-url {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
	
			.campaign-content-link-url {
				color: $neutral-2;
				font-size: 12px;
			}
		}

		.helper-text {
			text-align: right;
			color: $neutral-3;
			font-size: 12px;
			line-height: 1.5;
			padding-top: 0.5em;
		}
	}
}