.linked-idea-modal-search-input {
	display: flex;
	margin-top: 1em;
	margin-bottom: 1em;

	input {
		flex-grow: 1;
	}
}

.linked-content-column {
	@media (max-width: 790px) {
		margin-top: 30px;
	}
}