@import 'styles/_variables.scss';

.social-media-captions {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	border: none;
	padding: 1rem 1.5rem;
	width: 60%;
	
	@media (max-width: 790px) {
		width: 100%;
		padding: 0px;
	}

	.social-media-caption-name {
		display: flex;
		flex-direction: row;
		align-items: center;
		vertical-align: middle;
		border: none;
		padding: 0.5rem 1rem;
		font-size: 16px;

		@media (max-width: 790px) {
			flex-direction: column;
			align-items: flex-start;
		}

		> span {
			margin-right: 1rem;
		}
	}
}

.content-type-list.draggable-list .draggable-item {
	grid-template-columns: 20px 2fr 1fr 20px;
	width: 50%;

	@media (max-width: 790px) {
		width: 100%;
	}
}

.info-icon-color {
	color: $neutral-2;
	justify-self: center;
	margin-right: 8px;
}

.info-text-color {
	color: $neutral-2;
	font-size: 14px;
}

.social-content-type {
	background-color: transparent;
	text-overflow: ellipsis;
	max-width: 380px;

	@media (max-width: 790px) {
		max-width: 110px;
	}
}

.social-media-platforms {
	display: flex;
	padding-left: 1em;
	padding-right: 1em;
}

.new-content-popup {
	width: 820px;

	@media (max-width: 790px) {
		width: 350px;
	}
}

.new-content-type-input {
	@media (max-width: 790px) {
		width: 90%;
	}
}

.new-content-type-select {
	width: 100%;

	@media (max-width: 790px) {
		width: 90%;
		margin-top: -1px;
		margin-left: 1em;
	}
}

.new-content-type-menu {
	width: 100%;

	@media (max-width: 790px) {
		width: 90%;
		margin-top: -90%;
	}
}