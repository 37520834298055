@import 'styles/_variables.scss';

.tejik-toggle {
	display: flex;
	.tejik-toggle-option {
		&.tejik-button ~ .tejik-button {
			margin-right: -1px;
			color: $neutral-6;
			border: 1px solid $neutral-2;
			border-radius: 0px;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}

		&.tejik-button:first-of-type {
			margin-right: -1px;
			border: 1px solid $neutral-2;
			border-top-right-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		&.tejik-toggle-option-selected {
			background-color: $neutral-1;
			color: $neutral-6;
		}
	}
}
