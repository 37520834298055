@import 'styles/_variables.scss';

.project-statuses-table {
	margin-bottom: 1em;
}

.color-picker-label {
	margin-left: 4px;
	color: $neutral-3;
	font-size: 14px;
}

.color-picker {
	display: grid;
	grid-gap: 16px;
	margin: 12px 8px 0 8px;
    grid-template-columns: repeat(8, 20px);
    grid-template-rows: repeat(3, 20px);
	position: relative;
	
	> .color-preview {
		cursor: pointer;
		border-radius: 50%;

		&.selected {
			border: 1px solid $neutral-0;
    		box-shadow: 0 0 0 2px $neutral-6;
		}
	}

	div.color-preview:hover {
		border: 1px solid $neutral-0;
		box-shadow: 0 0 0 2px $neutral-1;
	}
}

.status-template-platforms {
	margin-right: -1em;
	> * {
		margin-left: 0.25em;
		margin-right: 0.25em;
	}
}

.status-template-actions {
	display: flex;
    justify-content: flex-end;

	> .tejik-button ~ .tejik-button {
		margin-left: 0;
	}
}

.assignee-selection {
	.assignee {
		display: grid;
		grid-template-columns: 40px 1fr 20px;
		align-items: center;
	    text-align: left;
		margin-bottom: 0.5em;
		cursor: pointer;

		&:hover {
			background-color: $hover;
		}
		
		p {
			margin-bottom: 0;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.draggable-list.statuses-list {
	padding: 0 2em 2em 2em;

	@media (max-width: 790px) {
		padding: 0 0 2em 0;
	}

	> .statuses-header {
		color: $neutral-3;
		margin-bottom: 0.5em;
		font-size: 14px;
	}

	.draggable-item {
		grid-template-columns: 32px 32px 1fr 32px 24px 40px;

		.not-visible {
			visibility: hidden;
		}
	}

	.draggable-padding {
		padding: 8px;
	}

	.status-color-preview {
		width: 20px;
		height: 20px;
	}

	.empty-list-placeholder {
		border: 1px dashed $neutral-2;
		color: $neutral-2;
		font-style: italic;
		padding: 0.75em;
	}
}

.new-status-template-popup {
	width: 800px;

	@media (max-width: 790px) {
		width: 350px;
	}
}

.new-status-template-input {
	width: 45%;

	@media (max-width: 790px) {
		width: 90%;
	}
}

.new-status-template-select {
	width: 100%;

	@media (max-width: 790px) {
		width: 90%;
		margin-top: -1px;
		margin-left: 1em;
	}
}

.new-status-template-menu {
	width: 100%;

	@media (max-width: 790px) {
		width: 90%;
		margin-top: -90%;
	}
}

.status-name {
	@media (max-width: 790px) {
		width: 50%;
	}
}

.status-name-input {
	background-color: $neutral-1;
}