.tejik-platform-selection {
	display: flex;
	flex-wrap: wrap;
	padding: 6px;

	@media (max-width: 790px) {
		padding: 6px 0px 6px 0px;
	}

	.tejik-platform-selection-button {
		display: flex;
		align-items: center;
		font-size: 32px;
			
		@media (max-width: 790px) {
			padding: 6px 4px 6px 0px;
		}

		&:not(.selected) {
			position: relative;
			color: $neutral-2;

			&::after {
				content: 'add_box';

				font-family: 'Material Icons';
				font-weight: normal;
				font-style: normal;
				font-size: inherit;
				display: inline-block;
				line-height: 1;
				text-transform: none;
				letter-spacing: normal;
				word-wrap: normal;
				white-space: nowrap;
				direction: ltr;

				/* Support for all WebKit browsers. */
				-webkit-font-smoothing: antialiased;
				/* Support for Safari and Chrome. */
				text-rendering: optimizeLegibility;

				/* Support for Firefox. */
				-moz-osx-font-smoothing: grayscale;

				/* Support for IE. */
				font-feature-settings: 'liga';

				position: absolute;
				bottom: 4px;
				right: 2px;
				font-size: 14px;
				color: $neutral-6;
			}
		}
	}
}
