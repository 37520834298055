.tejik-user-image {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	background-color: gainsboro;
	border: 1px solid black;
	border-radius: 100%;

	&.tejik-user-image-small {
		width: 20px;
		height: 20px;
		font-size: 10px;
	}

	&.tejik-user-image-xl {
		width: 120px;
		height: 120px;
		font-size: 60px;
	}
}