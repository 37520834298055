@import 'styles/_variables.scss';

.task-platform-name {
	display: flex;
    align-items: center;
	font-size: 14px;
}

.new-task-popup {
	width: 500px;

	@media (max-width: 790px) {
		width: 320px;
	}
}