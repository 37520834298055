@import 'styles/_variables.scss';

.tejik-campaign-tasks {
	border: 10px solid $tasks-modal;
	border-radius: 10px;
	padding: 1em;
	margin-bottom: 5em;
	
	@media (max-width: 790px) {
		margin-top: 1em;
	}

	.tejik-campaign-tasks-title {
		text-align: center;

		@media (max-width: 790px) {
			margin-bottom: 0.25em;
		}
	}
}

