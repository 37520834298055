@import 'styles/_variables.scss';

.help-card-container {
	margin-top: 2em;

	@media (max-width: 790px) {
		margin-top: 1em;
	}

	.help-top-button {
		border: 1.5px solid black;
		border-radius: 30px;
		padding: 1px;
		padding-top: 2px;
		padding-right: 2px;

		@media (max-width: 790px) {
			padding-top: 1px;
		}
	}

	.help-card {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		text-align: left;
		font-size: 14px;
		border: 1px solid $neutral-1;
		border-radius: 10px;
		padding: 2em;
	
		> div .help-card-title {
			display: flex;
			font-weight: 500;
			align-items: center;
			margin-bottom: 0.5em;
	
			> span {
				margin-right: 1em;
			}
		}
	
		> div .help-card-description {
			margin-bottom: 1.5em;
		}
	
		> .tejik-button > a {
			text-decoration: none;
		}

		> a {
			align-self: center;
			margin-top: 0.5em;
		}
	}
}
