@import 'styles/_variables.scss';

.ideas-container {
	width: 100%;
	margin-top: 1em;
}

.idea-status-subtitle {
	display: flex;
	padding: 0 0 0 0.5em;
	align-items: center;
	cursor: pointer;

	&:hover {
		background-color: $hover;
	}
}

.ideas-container-search {
	width: 330px;

	@media (max-width: 790px) {
		width: 100%;
	}
}

.ideas-list {
	height: auto;
	margin-top: -0.5em;
	transition: height 200ms ease-in-out;

	&.ideas-list-hidden {
		height: 0;
		overflow: hidden;
	}

	.tejik-button.ideas-to-campaigns-button {
		border-left-color: $neutral-1;
		border-radius: 0px;
		opacity: 1;

		&:hover {
			color: $secondary;
		}
	}

	.tejik-button.ideas-delete-button {
		padding: 0.5em 0.75em;
		border-left-color: $neutral-1;
		border-radius: 0px;

		&:hover {
			color: $secondary;
		}
	}
}