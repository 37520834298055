@import 'styles/_variables.scss';

.dashboard-table {
	.campaign-due-date {
		display: flex;
		
		> span {
			margin-right: 0.5em;
		}
	}

	.status-cell {
		padding: 0;
		height: 100%;
		width: 200px;
		text-align: center;

		> div {
			display: flex;
			padding: 11px 15px;
			justify-content: center;
		}
	}
}

.dashboard-card {
	> .item {
		display: flex;
		padding: 0.5em;
		margin-bottom: 0.5em;
		border: 1px solid $neutral-2;
		cursor: pointer;
		text-decoration: none;

		&:hover {
			background-color: $hover;
		}
	}
}