@import 'styles/_variables.scss';

.tejik-select {
	display: flex;
  align-items: center;
	justify-content: space-between;
	cursor: pointer;

	border: 1px $neutral-1 solid;
	background-color: $hover;
	border-radius: 10px;
	padding: 14px 26px;
	font-size: 14px;
	line-height: 1.2;

	@media (max-width: 790px) {
		padding: 14px 8px;
	}

	&.hidden {
		border: 0;
		padding: 0;
		
		.tejik-menu-item {
			min-width: auto;
		}
	}

	&.tejik-select-small {
		padding: 5px 10px;
		font-size: 15px;

		.tejik-menu-item {
			min-width: 150px;
		}
	}

	.tejik-select-value {
		flex-grow: 1;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		margin-left: -1em;
		padding-right: 1em;
	}

	.tejik-select-placeholder {
		color: $neutral-2;
	}

	.tejik-menu-item {
		min-width: 250px;
		margin-bottom: 0;

		@media (max-width: 790px) {
			max-width: 250px;
		}
	}

	.tejik-select-container {
		max-width: 100%;
	}

	.tejik-select-dropdown {
		width: 30px;
	}
}