@import 'styles/_variables.scss';

.tejik-date-picker-wrapper {
  > * {
    height: 100%;
  }
}

.react-datepicker {
	font-family: "Poppins", sans-serif;
  border: none;
	padding: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  width: auto;
	padding-bottom: 20px;
}

.react-datepicker__header {
  background-color: white;
  border-bottom: none;
  padding-top: 10px;
  position: relative;
  text-align: center;
}

.react-datepicker__month-container {
	margin: 14px;
	margin-bottom: 8px;
}

.react-datepicker__current-month {
  font-size: 14px;
	font-weight: 900;
  color: #000;
  margin: 0;
  padding-right: 200px;
}

.react-datepicker__navigation {
  position: absolute;
  width: 12px;
  height: 12px;
  cursor: pointer;
  z-index: 1;
  background: none;
}

.react-datepicker__navigation--previous {
  right: 80px;
	top: 30px;
	left: auto;
  border-width: 6px 6px 6px 0;
  border-color: transparent #000 transparent transparent;
  border-style: solid;
}

.react-datepicker__navigation--next {
  right: 30px;
	top: 30px;
  border-width: 6px 0 6px 6px;
  border-color: transparent transparent transparent #000;
  border-style: solid;
}

.react-datepicker__day-names {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 10px;
}

.react-datepicker__day-name {
	width: 36px;
  font-weight: 800;
  text-align: center;
}

.react-datepicker__month {
  display: grid;
  grid-template-columns: repeat(7, 1fr); 
	margin: 0;
}

.react-datepicker__week {
  display: contents;
}

.react-datepicker__day {
  width: auto;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  padding: 0;
	margin: 0;
  transition: background-color 0.2s ease;
	border-right: 1px solid $neutral-1;
	border-bottom: 1px solid $neutral-1;
}

.react-datepicker__day:hover {
  background-color: #f0f0f0;
	border-radius: 0;
  cursor: pointer;
}

.react-datepicker__day--selected {
  background-color: #2c3e50;
	border-radius: 0;
  color: white;
  font-weight: bold;
}

.react-datepicker__day:nth-child(7n-6) {
  border-left: 1px solid $neutral-1;
}

.react-datepicker__week:first-child .react-datepicker__day {
  border-top: 1px solid $neutral-1;
}

.react-datepicker__week:last-child .react-datepicker__day {
  border-bottom: 1px solid $neutral-1;
}

.react-datepicker__week:first-child .react-datepicker__day:nth-child(7n-6) {
  border-top-left-radius: 10px;
}

.react-datepicker__week:first-child .react-datepicker__day:nth-child(7n) {
  border-top-right-radius: 10px;
}

.react-datepicker__week:last-child .react-datepicker__day:nth-child(7n-6) {
  border-bottom-left-radius: 10px;
}

.react-datepicker__week:last-child .react-datepicker__day:nth-child(7n) {
  border-bottom-right-radius: 10px;
}

.react-datepicker__day--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__day--outside-month {
  pointer-events: none;
  background-color: $hover;
	opacity: 0.8;
}