@import 'styles/_variables.scss';

.tejik-info-button {
	position: fixed;
	right: 20px;
	bottom: 16px;
	cursor: pointer;
	z-index: 1;

	> span {
		color: $neutral-4;
	}

	&:hover > span {
		color: $neutral-5;
	}
}

.tejik-info-container {
	position: fixed;
    right: 8px;
    bottom: 8px;
    background-color: $neutral-0;
    border: 1px solid $neutral-2;
	border-radius: 10px;
    padding: 1.5rem;
	max-width: 500px;
	font-size: 14px;
    color: $neutral-5;

	@media (max-width: 790px) {
		max-width: 380px;
	}
}