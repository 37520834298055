@import 'styles/_variables.scss';

.new-pinterest-pin-modal {
	.tejik-modal-header {
		align-items: center;
	}
	
	.tejik-modal-header-actions > .tejik-platform-selection {
		justify-content: flex-end;
	}
}

.pinterest-post-container {
	display: flex;	
	margin-bottom: 2em;
	min-height: 750px;
	overflow-x: auto;

	.pinterest-post {
		min-width: 340px;
		max-width: 340px;
    margin-right: 2em;

		> .tejik-popover {
			display: flex;
			justify-content: flex-end;
			margin-bottom: -2.5em;
		}

		.not-configured-pin {
			height: 95%;
			background-color: $secondary;
			border-radius: 10px;
			align-items: center;
			position: relative;

			@media (max-width: 790px) {
				min-height: 750px;
			}

			> .tejik-container-column {
				text-align: center;
			}
		}
	}
}

.pinterest-pins-list {
	.pinterest-pin-title {
		display: flex;
		align-items: center;

		.pinterest-pin-due-date {
			display: flex;
			padding-right: 1rem;
		}
	}

	.pinterest-pin-actions {
		padding: 0 24px;
		display: flex;
		align-items: center;
	}
}

.tejik-pinterest-widget-status {
	width: fit-content;
	font-size: 14px;
	padding: 0.5em;
	border-radius: 10px;
}

.campaign-pinterest-delete-link {
	display: flex;
	align-items: center;
	margin-left: auto;
	cursor: pointer;
}

.pinterest-pin-content-links {
	@media (max-width: 790px) {
		margin-bottom: 3em;
	}
}

.pinterest-modal-container {
	display: flex;
	flex-direction: row;

	@media (max-width: 790px) {
		flex-direction: column;
	}
}

.pinterest-modal-research-container {
	width: 55%;
	margin-right: 2em;

	@media (max-width: 790px) {
		width: 100%;
	}
}

.pinterest-pin-form {
	width: 340px;
	margin-top: 0px;
}

.pinterest-pin-form-modal {
	margin-top: -1.25em;
	width: auto;

	@media (max-width: 790px) {
		margin-top: 0.5em;
	}
}