@import 'styles/_variables.scss';

.tejik-list {
	display: flex;
	flex-direction: column;
	border-collapse: separate;
	border-spacing: 0;

	.tejik-list-item {
		display: flex;

		border: 1px solid $neutral-1;
		font-size: 16px;

		> *:first-child {
			flex-grow: 1;
			padding: 16px 24px;
		}

		&:not(:first-child) {
			border-top: none;
		}

		&.tejik-list-item-first {
			border-top-left-radius: 10px; 
			border-top-right-radius: 10px; 
		}
		
		&.tejik-list-item-last {
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
		
		&.tejik-list-item-selected {
			cursor: pointer;
			background-color: $hover;
		}

		.tejik-list-item-actions {
			display: flex;
			justify-content: flex-end;

			.tejik-list-item-action {
				display: flex;
				align-items: center;
			}

			.tejik-list-item-redirect {
				display: flex;
				align-items: center;
				padding-left: 0.5em;
				padding-right: 0.5em;
			}
		}

		&.tejik-list-item-expandable, &.tejik-list-item-clickable {
			cursor: pointer;

			&:hover {
				background-color: $hover;
			}

			.tejik-list-item-actions button.tejik-button:hover {
				opacity: 1;
			}
		}
	}
	
	.tejik-list-item-expanded {
		max-height: 0;
		overflow: hidden;
		transition: padding 300ms ease, max-height 300ms ease;
		
		&.active {
			overflow: initial;
		}

		> .tejik-list-item {
			border-top: none;
		}
	}
}