$primary: #E56B6F;
$secondary: #EAAC8B;
$ternary: #355070;

$error: red;
$info: blue;
$success: green;

$hover: #F5F5F5;
$grayed: #D9D9D9;
$dark-gray: #707070;
$modal: #E5E5E5;

$neutral-0: #FFFFFF;
$neutral-1: #D8D8D8;
$neutral-2: #B2B1B1;
$neutral-3: #909090;
$neutral-4: #4B4B4B;
$neutral-5: #232323;
$neutral-6: #000000;

$tasks-modal: #F5F6F6;
$hover-color: #F2F2F2;