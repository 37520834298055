@import 'styles/_variables.scss';

.tejik-switch {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  .label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .label {
      margin-bottom: 0;
	  
      @media (max-width: 768px) {
	  	margin-left: 0.5em;
	  }
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: var(--switch-width);
    height: var(--switch-height);
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #B9B9B9;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: var(--slider-height);
    width: var(--slider-height);
    left: calc(var(--switch-padding) / 2);
    bottom: calc(var(--switch-padding) / 2);
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $secondary;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px $secondary;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(var(--slider-on));
    -ms-transform: translateX(var(--slider-on));
    transform: translateX(var(--slider-on));
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: var(--slider-height);
  }

  .slider.round:before {
    border-radius: 50%;
  }

  * {
    --switch-height: 22px;
    --switch-padding: 8px;
    --switch-width: calc((var(--switch-height) * 2) - var(--switch-padding));
    --slider-height: calc(var(--switch-height) - var(--switch-padding));
    --slider-on: calc(var(--switch-height) - var(--switch-padding));
  }
}