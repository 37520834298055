@import 'styles/_variables.scss';

.character-limit-text {
	text-align: right;
	padding-top: 0.5em;
	height: 0;
	font-size: 12px;	
	color: $neutral-2;
}

.campaign-tasks-container {
	border-left: 1px solid $neutral-2;
    padding-left: 1em;
}

.trash-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.campaign-email-delete-link {
	display: flex;
	align-items: center;
	margin-left: auto;
	cursor: pointer;
}
