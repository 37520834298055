@import 'styles/_variables.scss';

.character-limit-description {
	display: flex;
	justify-content: center;
	font-size: 12px;

	> :first-child { 
		display: flex;
		justify-content: center;
		padding: 0.5em;
		margin-right: 1em;

		> span {
			margin-right: 1em;
		}
	}

	@media (max-width: 790px) {
		margin-top: -1.5em;
	}
}

.info-icon {
	color: $neutral-3;
}

.character-limits-cell {
	display: flex;
	flex-direction: column;

	> * {
		margin-bottom: 0.5em;

		&:last-child {
			margin-bottom: 0;
		}
	}
	
	.empty-input {
		background-color: $neutral-1;
	}
}

.character-limit-table {
	margin-bottom: 2.5em;

	@media (max-width: 790px) {
		width: 350px;
		overflow-x: auto;
	}
}