@import 'styles/_variables.scss';

.tejik-button {
	background-color: $ternary;
	font-family: 'Poppins', sans-serif;
	border-radius: 10px;
	font-weight: 400;
	color: $neutral-0;
	font-size: small;
	font-weight: 400;
	padding: 6px 18px;
	line-height: 1.25;
	text-align: center;
	border: 0px;
	width: max-content;
	cursor: pointer;
	z-index: 0;
	transition: color ease-in-out 200ms, background-color ease-in-out 200ms;

	&:hover {
		opacity: 0.8;
	}

	& ~ & {
		margin-right: 8px;
	}

	&:first-of-type {
		margin-right: 8px;
	}
	
	&:last-of-type {
		margin-right: 0px;
	}

	// Button Sizes
	&.tejik-button-large {
		font-size: 20px;
		padding: 10px;
	}

	&.tejik-button-small, &.tejik-button-small.tejik-button-icon{
		font-size: 14px;
		padding: 1em 1em;
	}

	// Button Types
	&.tejik-button-primary {
		background-color: $ternary;
		color: $neutral-0;
		font-size: 14px;
		padding: 12px 18px;
		align-items: center;
		display: flex;
		height: 36px;
	}

	&.tejik-button-secondary {
		background-color: $ternary;
		color: $neutral-0;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		font-size: 14px;
		height: 36px;

		&.tejik-button-hollow {
			background-color: transparent;
			border-color: $ternary;
			color: $ternary;

			&:hover {
				background-color: $ternary;
				color: $neutral-0;
			}
		}
	}

	&.tejik-button-black {
		background-color: transparent;
		color: $neutral-6;
		font-weight: bold;
		border: 1px solid $neutral-6;

		&:hover {
			background-color: $neutral-5;
			color: $neutral-0;
			border: 1px solid $neutral-5;
		}
	}
	
	&.tejik-button-hollow {
		background-color: transparent;
		color: $ternary;
		border-radius: 10px;
		padding: 1em 1em;
		border: 1px solid $ternary;
		align-items: center;
		display: flex;
		height: 36px;
		
		&:hover {
			background-color: $ternary;
			color: $neutral-0;
		}
	}

	&.tejik-button-hollow-no-hover {
		background-color: $ternary;
		color: $neutral-0;
		border-radius: 10px;
		padding: 1em 1em;
		border: 1px solid $ternary;
		align-items: center;
		display: flex;
		height: 36px;
	}

	&.tejik-button-hollow-dark {
		background-color: transparent;
		color: $neutral-6;
		border-radius: 10px;
		padding: 1em 1em;
		border: 1px solid $neutral-6;
		align-items: center;
		display: flex;
		height: 36px;
		
		&:hover {
			background-color: $ternary;
			color: $neutral-0;
		}
	}

	&.tejik-button-hollow-gray {
		background-color: transparent;
		color: $neutral-6;
		border-radius: 10px;
		padding: 1em 1em;
		border: 1px solid $neutral-2;
		align-items: center;
		display: flex;
		height: 36px;
		
		&:hover {
			background-color: $ternary;
			color: $neutral-0;
		}
	}

	&.tejik-button-hollow-gray-no-hover {
		background-color: $ternary;
		color: $neutral-0;
		border-radius: 10px;
		padding: 1em 1em;
		border: 1px solid $neutral-2;
		align-items: center;
		display: flex;
		height: 36px;
	}

	&.tejik-button-greyed {
		background-color: $hover;
		color: $neutral-6;
		border-radius: 10px;
		padding: 1em 1em;
		border: 1px solid $neutral-2;
		align-items: center;
		justify-content: center;
		display: flex;
		height: 36px;
		
		&:hover {
			background-color: $ternary;
			color: $neutral-0;
		}
	}

	&.tejik-button-header {
		background-color: transparent;
		color: $neutral-6;
		border-radius: 10px;
		padding: 1em 1em;
		border: 1px solid $neutral-6;
		align-items: center;
		display: flex;
		height: 32px;
		
		&:hover {
			background-color: $ternary;
			color: $neutral-0;
		}
	}

	&.tejik-button-link {
		background-color: transparent;
		color: inherit;
		border-color: transparent;
		text-decoration: underline;
		padding: 0;
	}

	&.tejik-button-text {
		background-color: transparent;
		color: $neutral-6;
		border: 1px solid transparent;
	}

	&.tejik-button-icon {
		padding: 6px;
		font-size: 14px;
		
		.tejik-button-prefix-icon {
			margin-right: 0;
		}

		.tejik-button-suffix-icon {
			margin-left: 0;
		}
	}

	.tejik-button-prefix-icon {
		vertical-align: top;
		margin-right: 0.5rem;
	}

	.tejik-button-suffix-icon {
		vertical-align: top;
		margin-left: 0.5rem;
	}
}
