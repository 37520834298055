@import 'styles/_variables.scss';

.hashtag-group-name {
	background-color: transparent;
}

.popup-menu-container {
	justify-content: flex-start;
	margin-bottom: 1em;
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (max-width: 790px) {
		flex-direction: column;
		align-items: flex-start;
	}
}

.popup-menu-platform {
	width: 80px;
	margin-right: 1rem;
	font-size: 14px;
}

.popup-menu-line {
	display: flex;
	flex-direction: row;
	align-items: center;

	@media (max-width: 790px) {
		margin-top: 10px;
	}
}

.popup-menu-share {
	width: 30px;
	margin-right: 1rem;
	font-size: 14px;
}

.popup-menu-input {
	width: 120px;
	margin-right: 1rem;
	height: 32;
	font-size: 14px;

	@media (max-width: 790px) {
		width: 96px;
	}
}

.popup-menu-label {
	width: 150px;
	font-size: 14px;
}

.popup-menu-title {
	margin: 1em;
	color: $neutral-6;
}

.platforms-settings-popup {
	width: 520px;

	@media (max-width: 790px) {
		width: 350px;
	}
}

.hashtag-search-bar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.new-hashtag-input {
	@media (max-width: 790px) {
		width: 90%;
	}
}

.new-hashtag-select {
	@media (max-width: 790px) {
		width: 90%;
		margin-top: -1px;
		margin-left: 1em;
	}
}

.new-hashtag-menu {
	@media (max-width: 790px) {
		width: 90%;
		margin-top: -90%;
	}
}

.new-hashtag-button {
	margin: 0px;

	@media (max-width: 790px) {
		margin: inherit;
	}
}