@import 'styles/_variables.scss';

.category-card {
	display: flex;
    flex-direction: column;
    align-items: center;
	justify-content: center;
	border-radius: 10px;
	
	position: relative;
	border: 1px solid $neutral-2;
	padding: 32px 16px;
	height: 100%;
	cursor: pointer;

	.category-card-title {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 0.5em;
	}

	.category-card-action-overlay {
		display: none;
    	justify-content: space-around;
    	align-items: center;
		
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #F5F6F8B3;
		cursor: auto;

		> div {
			cursor: pointer;
		}

		&.active {
			display: flex;
		}

		&::after {
			content: "";
			border-left: 1px solid;
			border-right: 1px solid;
			position: absolute;
			left: 50%;
			top: 20%;
			height: 60%;
		}
	}
}

.create-popup-message { 
	width: '100%';
	font-size: 14px;
	line-height: 1.6;
	color: $neutral-6;
}