$typography: (
	'h1': (
		16px,
		44px,
		-0.022em,
	),
	'h2': (
		16px,
		36px,
		-0.021em,
	),
	'h3': (
		16px,
		32px,
		-0.02em,
	),
	'h4': (
		16px,
		32px,
		-0.019em,
	),
	'h5': (
		16px,
		28px,
		-0.017em,
	),
	'h6': (
		16px,
		24px,
		-0.011em,
	),
);

.material-icons, .material-icons-outlined {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: inherit;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;

	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;

	/* Support for IE. */
	font-feature-settings: 'liga';
}

.material-icons-outlined { 
	font-family: 'Material Icons Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: inherit;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
	font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}
