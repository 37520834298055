@import 'styles/_variables.scss';

.campaigns-container {
	margin-top: 1em;
}

.campaigns-list {
  margin-bottom: 2.5em;

  @media (max-width: 790px) {
    margin-bottom: 4em;
  }

  .tejik-menu-item {
    &:last-of-type {
      margin-bottom: 12px;
    }
  }
  
  .tejik-list-item-actions {
    > * {
      border-left: 1px solid $neutral-2;
    }

    .campaign-actions {
      padding: 0 24px;
      display: flex;
      align-items: center;

      &.fixed-width {
        width: 150px;
      }

      &.status-button {
        padding: 0;

        > .tejik-popover {
          display: flex;
          width: 100%;
          height: 100%;

          > div:first-child {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
          }
        }
      }

      &.campaign-assignee {
        width: 80px;
        justify-content: center;
      }
    }

    .campaign-date-picker {
      display: flex;
      align-items: stretch;

      .campaign-date-picker-input {
        display: flex;
        align-items: center;
        padding: 0 24px;
        height: 100%;
        min-width: 150px;
      }
    }
  }

  .campaign-platforms {
    display: flex;
    flex-wrap: wrap;

    .campaign-platform-container {
      display: grid;
      grid-template-columns: 1fr 32px auto auto auto 150px;
      grid-gap: 16px;
      align-items: center;

      > *:not(.campaign-platform-channel-name) {
        color: $neutral-6;
      }

      .campaign-platform-item-date {
        display: flex;
        padding: 0.25em 1em;
        border: 1px solid $neutral-1;

        > span {
          margin-right: 0.75em;
        }
      }
    }
  }
}

// Campaign View
.campaign-header-container {
  > h4 {
    width: 100%;

    input {
      font-size: inherit;
      padding: inherit;
    }
  }
}

.tejik-form-item.campaign-idea-notes {
  padding: 0;

  textarea {
    min-height: auto;
  }
}

.campaign-settings {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.campaign-third-col-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.campaign-delete-btn-container {
  color: $neutral-3;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.campaign-delete-btn-text {
  font-size: 12px;
  margin-right: 0.5em;

  @media (max-width: 790px) {
    margin-top: 1.5em;
  }
}

.campaign-delete-btn-icon {
  padding: 0;
  color: #909090;
  margin-top: 4px;

  @media (max-width: 790px) {
    margin-top: 1.5em;
  }
}

.date-range-span {
	width: 110px;
}

.campaign-notes {
  width: calc(100vw - 265px);
  position: absolute;
  box-sizing: border-box;
  left: 252px;
  bottom: 0;
	padding: 1em 2em 1.5em 2em;
  box-shadow: 0px -2px 12px $hover;
  z-index: 100;
  background-color: $neutral-0;
}