@import 'styles/_variables.scss';

.tejik-ideas-drawer-container {
  padding: 1rem;
  min-width: 250px;
  max-width: 300px;
  border: 1px solid $neutral-1;
  border-radius: 10px;

  & .close-button {
    cursor: pointer;
  }

  & .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & .title-icon{
      display: flex;
      flex-direction: row;
    }
  }
}

.tejik-ideas-drawer-item {
  border-radius: 10px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  cursor: pointer;

  & .title {
    margin: 0;
  }
}