@import 'styles/_variables.scss';

.tejik-editor-container {
	border-radius: 10px;
	color: #000;
	position: relative;
	line-height: 20px;
	font-weight: 400;
	text-align: left;
	border: 1px solid $neutral-2;
}

.tejik-editor-inner {
	border-radius: 10px;
	background: #fff;
	position: relative;
}

.tejik-editor-input {
	min-height: 200px;
	resize: none;
	font-size: 14px;
	caret-color: rgb(5, 5, 5);
	position: relative;
	tab-size: 1;
	outline: 0;
	padding: 15px 10px;
	caret-color: #444;
	box-sizing: border-box;
	overflow-wrap: break-word;
	overflow-y: auto;
}

.tejik-editor-placeholder {
	color: #999;
	overflow: hidden;
	position: absolute;
	text-overflow: ellipsis;
	top: 15px;
	left: 10px;
	font-size: 14px;
	user-select: none;
	display: inline-block;
	pointer-events: none;
}

.tejik-editor-copy-icon {
	position: absolute;
	cursor: pointer;
	right: 0.5em;
	bottom: 0.75em;
}

.tejik-editor-text-bold {
	font-weight: bold;
}

.tejik-editor-text-italic {
	font-style: italic;
}

.tejik-editor-text-underline {
	text-decoration: underline;
}

.tejik-editor-text-strikethrough {
	text-decoration: line-through;
}

.tejik-editor-text-underlineStrikethrough {
	text-decoration: underline line-through;
}

a[href].tejik-editor-link {
	color: rgb(33, 111, 219);
	text-decoration: none;
}

.tejik-editor-paragraph {
	margin: 0;
	margin-bottom: 8px;
	position: relative;
}

.tejik-editor-paragraph:last-child {
	margin-bottom: 0;
}

.tejik-editor-list-ol {
	padding: 0;
	margin: 0;
	margin-left: 16px;
	list-style-type: decimal;
}

.tejik-editor-list-ul {
	padding: 0;
	margin: 0;
	margin-left: 16px;
	list-style-type: disc;
}

.tejik-editor-listitem {
	margin: 8px 32px 8px 32px;
}

.tejik-editor-nested-listitem {
	list-style-type: none;
}

pre::-webkit-scrollbar {
	background: transparent;
	width: 10px;
}

pre::-webkit-scrollbar-thumb {
	background: #999;
}

.toolbar-container {
	overflow: auto;
	border-bottom: 1px solid $neutral-2;
}

.toolbar {
	display: flex;
	background: $neutral-0;
	padding: 4px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	vertical-align: middle;
	overflow: none;
}

.toolbar button.toolbar-item {
	border: 0;
	display: flex;
	align-items: center;
	background: none;
	border-radius: 10px;
	padding: 8px;
	cursor: pointer;
	vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
	cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
	margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
	background-size: contain;
	display: inline-block;
	height: 18px;
	width: 18px;
	margin-top: 2px;
	vertical-align: -0.25em;
	display: flex;
	opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
	opacity: 0.2;
}

.toolbar button.toolbar-item.active {
	background-color: rgba(223, 232, 250, 0.3);
}

.toolbar .toolbar-item:hover:not([disabled]) {
	background-color: #eee;
}

.toolbar .divider {
	width: 1px;
	background-color: #eee;
	margin: 0 4px;
}

.toolbar .toolbar-item .text {
	display: flex;
	line-height: 20px;
	vertical-align: middle;
	font-size: 12px;
	color: #777;
	text-overflow: ellipsis;
	width: 60px;
	overflow: hidden;
	height: 20px;
	text-align: left;
}

#block-controls button:hover {
	background-color: #efefef;
}

#block-controls button:focus-visible {
	border-color: blue;
}

#block-controls span.block-type {
	background-size: contain;
	display: block;
	width: 18px;
	height: 18px;
	margin: 2px;
}

.link-editor {
	position: absolute;
	z-index: 100;
	top: -10000px;
	left: -10000px;
	margin-top: -6px;
	max-width: 300px;
	width: 100%;
	opacity: 0;
	background-color: #fff;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	transition: opacity 0.5s;
}

.link-editor .link-input {
	display: flex;
	justify-content: space-between;
	width: calc(100% - 24px);
	box-sizing: border-box;
	margin: 8px 12px;
	padding: 8px 12px;
	border-radius: 15px;
	background-color: #eee;
	font-size: 15px;
	color: rgb(5, 5, 5);
	border: 0;
	outline: 0;
	position: relative;
	font-family: inherit;
	line-height: 1.5;

	> a[href] {
		text-wrap: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	> .link-edit {
		cursor: pointer;
		border: 0;
		width: 22px;
		display: flex;
		height: 22px;
		justify-content: center;
		align-items: center;
		border-radius: 50px;

		&:hover {
			background-color: $neutral-1;
		}
	}
}

.link-editor div.link-edit {
	background-size: 16px;
	background-position: center;
	background-repeat: no-repeat;
	width: 35px;
	vertical-align: -0.25em;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
}

.link-editor .button {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 6px;
	border-radius: 8px;
	cursor: pointer;
	margin: 0 2px;
}

.link-editor .button.hovered {
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: #eee;
}

.link-editor .button i,
.actions i {
	background-size: contain;
	display: inline-block;
	height: 20px;
	width: 20px;
	vertical-align: -0.25em;
}
