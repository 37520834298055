@import 'styles/_variables.scss';

.tejik-new-entity {
	margin: 0.25em 0 0.5em 0;

	.tejik-entity-fields {
		display: flex;
		flex-direction: row; 
		width: 100%;

		@media (max-width: 790px) {
			flex-direction: column; 
		}
	}

	> span {
		font-size: 24px;
		margin-right: 8px;
	}
	
	.tejik-select, input {
		padding: 0 16px;
	}

	.tejik-select {
		min-height: 36px;
		border-right: 0px;
		border-radius: 0px;
	}

	.rounded {
		@media (max-width: 790px) {
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
		}
	}

	.tejik-select .tejik-menu-item {
		min-width: 150px;
		padding: 1px;
	}
	
	input {
		height: 36px;
		margin: 0 0 0 1em;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	::-webkit-input-placeholder {
		color: $neutral-2;
	}

	.tejik-button {
		margin: 0 0 0 -4px;
		min-width: 200px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	@media (max-width: 790px) {
		input {
			height: 36px;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}

		.tejik-select {
			border-right: 1px solid $neutral-1;
		}

		.tejik-button {
			margin-left: 1em;
			margin-top: 1em;
			border-radius: 10px;
			width: fit-content;
			flex-grow: 1em;
		}
	}
}

.tejik-new-entity-single {
	border-bottom-right-radius: 0px;

	.tejik-entity-fields {
		display: flex;
		flex-direction: row; 
		width: 100%;

		@media (max-width: 790px) {
			flex-direction: column; 
		}
	}

	input {
		height: 36px;
		margin: 0 0 0 1em;
		border-top-right-radius: 0px;
		border-bottom-right-radius: 0px;
	}

	.tejik-button {
		margin: 0 0 0 -4px;
		min-width: 200px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}

	@media (max-width: 790px) {
		input {
			height: 36px;
			border-right: 1px solid $neutral-1;
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
			border-bottom-left-radius: 10px;
		}

		.tejik-button {
			margin-left: 1em;
			margin-top: 1em;
			border-radius: 10px;
			width: fit-content;
			flex-grow: 1em;
		}
	}
}
