@import 'styles/_variables.scss';

.keyword-research-button {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: 0.5rem;
	
	> .permalink {
		font-size: 12px;	
		color: $neutral-2;
	}
	
	> .tejik-button {
		float: right;
		z-index: 1;
	}
}

.character-limit-text {
	text-align: right;
	padding-top: 0.5em;
	height: 0;
	font-size: 12px;	
	color: $neutral-2;
}

.campaign-tasks-container {
	border-left: 1px solid $neutral-2;
    padding-left: 1em;
}

.button-trash-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 10px;
}

.campaign-blogpost-delete-link {
	display: flex;
	align-items: center;
	margin-left: auto;
	cursor: pointer;
}