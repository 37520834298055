@import 'styles/_variables.scss';

.tejik-popover {
	position: relative;
	
	.tejik-popover-content {
		position: absolute;
		top: 100%;
		right: 0;
		display: none;
		margin-top: 4px;
		padding: 12px;
		z-index: 2;

		background-color: $neutral-0;
		box-shadow: 0px 8px 28px 8px $modal;
		border-radius: 10px;
		color: $neutral-6;
		border: 1px solid $modal;

		@media (max-width: 790px) {
			right: 5%;
		}

		&.contained {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 100%;
		}

		&.top { 
			left: 0;
			right: initial;
			top: initial;
			bottom: 100%;
			margin-bottom: 4px;
		}

		&.left { 
			left: 0;
			right: initial;
		}
		
	}

	&.tejik-popover-active {
		> .tejik-popover-content {
			display: block;
		}
	}
}
