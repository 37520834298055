@import 'styles/_variables.scss';

input {
	border: 1px $neutral-1 solid;
	border-radius: 0;
	padding: 14px 26px;
	font-family: 'Poppins';
	font-size: 14px;
	line-height: 1.2;
	width: 100%;
  	box-sizing: border-box;
	background-color: $hover;
	border-radius: 10px;
	text-overflow: ellipsis;

	&.hidden {
		border: 0;
	}

	&.small {
		padding: 5px 10px;
		font-size: 15px;
	}

	&:focus {
		outline: none !important;
    border: 2px $ternary solid;

		&.has-filters-applied {
			border-left: none;
		}
	}

	&.has-filters {
		border-left: none;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
}

input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
}

.preffix {
	margin-right: 1em;
	align-self: center;
	color: $neutral-3;
	width: fit-content;
}

.tejik-input-preffix {
	flex-direction: row;
	display: flex;
}

.tejik-input-wrapper {
	width: 100%;
	flex-direction: row;
	display: flex;
}

.tejik-input-icon-wrapper {
	display: flex;
	position: relative;
	width: 100%;

	.icon {
		position: absolute;
		top: 50%;
		right: 8px;
		height: auto;
		background-color: $neutral-0;
		transform: translateY(-50%);
	}

	.left-icon {
		position: absolute;
		top: 50%;
		left: 8px;
		height: auto;
		background-color: $neutral-0;
		transform: translateY(-50%);
	}

	.filter {
		z-index: 10;
		display: flex;
		height: 100%;
		left: 8px;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border: 1px solid #D8D8D8;
		background-color: $neutral-0;
		padding-left: 0.5em;
		padding-right: 0.25em;
		align-items: center;

		&:hover {
			cursor: pointer;
		}
	}

	.badge {
		background-color: $neutral-6;
		color: $neutral-0;
		position: absolute;
		border-radius: 50%;
		width: 16px;
		height: 16px;
		top: -8px;
		left: 50px;
		font-size: 11px;
		line-height: 16px;
		padding-top: 1px;
		font-weight: bold;
		text-align: center;
	}

	.filtered {
		z-index: 100;
		border: 2px solid $neutral-6;
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
	}

	.filter-icon {
		position: relative;
		left: -2px;
	}

	textarea ~ .icon {
		top: auto;
		bottom: 0;
		transform: translateY(-50%);
	}
}

textarea { 
	border: 1px $neutral-1 solid;
	border-radius: 10px;
	font-family: inherit;
	padding: 14px 26px;
	resize: vertical;
	min-height: 120px;
	width: 100%;

	&.hidden {
		border: 0;
	}

}

input[type="date" i] {
	font-family: inherit;
}
