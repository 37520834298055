@import 'styles/_variables.scss';

.workspaces-list {
	.workspace-item {
		display: grid;
		grid-template-columns: 46px 1fr 2fr 22px;
		justify-content: center;
		padding: 14px 16px;
		border: 1px solid $neutral-1;
		border-radius: 10px;
		cursor: pointer;

		> div {
			display: flex;
			align-items: center;
		}

		.workspace-icon {
			font-size: 16px;
			font-weight: 700;
		}

		.workspace-info {
			align-items: flex-start;
			flex-direction: column;

			.workspace-name {
				font-size: 16px;
				font-weight: 500;
				margin-bottom: 4px;
				width: 300px;
			}

			.workspace-users {
				display: flex;
				> * {
					margin-right: 4px;

					&:last-child {
						margin-right: 0;
					}
				}
			}
		}

		.workspace-platforms {
			font-size: 20px;
			> * {
				margin-right: 8px;
			}
		}
	}
}

.workspaces-new-workspace {
	display: flex;
	justify-content: center;

	.tejik-button {
		max-width: 250px;
		width: 100%;
	}
}