@import 'styles/_variables.scss';

.main {
	display: flex;
	height: 100%;

	> .top-content {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	> .main-content {
		width: 100%;
    	overflow-y: auto;
	}
}

.workspace-cards {
	.workspace-card {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		flex-direction: column;
		border: 1px solid $neutral-1;
		padding: 2rem 4rem;
		cursor: pointer;

		.workspace-shorthand {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 32px;
			height: 32px;
			border: 1px solid $neutral-3;
			background-color: $neutral-1;
			border-radius: 100%;
			margin-bottom: 1em;
		}
		
		&:hover {
			background-color: $neutral-1;
		}
	}
}