@import 'styles/_variables.scss';

.new-user-dropdown {
	background-color: transparent;
}

.user-workspace-dropdown {
	background-color: $neutral-0;
}

.new-user-role-select {
	@media (max-width: 790px) {
		margin-left: 1em;
		width: 330px;
	}
}

.new-user-workspace-select {
	@media (max-width: 790px) {
		margin-left: 1em;
		width: 330px;
	}
}

.user-table {
	@media (max-width: 790px) {
		width: 350px;
		overflow-x: auto;
	}
}