@import '../../../../styles/utils.scss';

.workspace-platform-list,
.workspace-users-list {
	font-size: 30px;

	.workspace-platform-button {
		font-size: 30px;

		&:not(.active) {
			color: rgba(0, 0, 0, 0.2);
		}
	}

	.workspace-list-button {
		padding: 6px;

		&:not(.active) > *{
			opacity: 0.6;
		}
	}
}

.workspace-users-list {
	.workspace-list-button {
		&:not(.active) {
			position: relative;

			&::after {
				content: 'add_box';
				font-family: 'Material Icons';
				font-weight: normal;
				font-style: normal;
				font-size: inherit;
				display: inline-block;
				line-height: 1;
				text-transform: none;
				letter-spacing: normal;
				word-wrap: normal;
				white-space: nowrap;
				direction: ltr;

				/* Support for all WebKit browsers. */
				-webkit-font-smoothing: antialiased;
				/* Support for Safari and Chrome. */
				text-rendering: optimizeLegibility;

				/* Support for Firefox. */
				-moz-osx-font-smoothing: grayscale;

				/* Support for IE. */
				font-feature-settings: 'liga';

				position: absolute;
				bottom: 4px;
				right: 2px;
				font-size: 14px;
				color: $neutral-6;
			}
		}
	}
}

.divider {
	border-top: 1px solid #B2B1B1;
}

.actions {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 1em 0 1em;

	@media (max-width: 790px) {
		padding: 0px;
	}
}

.filters-form-item {
	padding: 0px;
}

.planner-filter-form {
	@media (max-width: 790px) {
		padding: 0 1em 0 1em;
	}
}