@import 'styles/_variables.scss';

.workspace-platform-list,
.workspace-users-list {
	font-size: 30px;

	.workspace-platform-button {
		font-size: 30px;

		&:not(.active) {
			color: rgba(0, 0, 0, 0.2);
		}
	}

	.workspace-list-button {
		padding: 6px;

		&:not(.active) > *{
			opacity: 0.6;
		}
	}
}

.workspace-domain-imput {
	width: 100%;
}

.workspace-additional-settings {
	display: grid;
}

.workspace-save {
	display: flex;
	justify-content: flex-end;

	.tejik-button {
		max-width: 160px;
		width: 100%;
	}
}