@import 'styles/_variables.scss';
@import 'styles/reset.scss';
@import 'styles/colors.scss';
// @import '@app/styles/grid.scss';
@import 'styles/utils.scss';
@import 'components/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	height: 100%;
}

#root {
	height: 100vh;
	overflow: hidden;
}

a[href] {
	color: inherit;
}

b, strong {
	font-weight: 600;
}

i {
	font-style: italic;
}

p {
	line-height: 1.2;
	margin-bottom: 0.5em;
}