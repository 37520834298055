@import 'styles/_variables.scss';

table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
	font-size: 14px;

	thead {
		text-align: left;
		color: $neutral-3;

		tr {
			th {
				padding: 12px 16px;
				white-space: nowrap;
			}
		}
	}

	tbody {
		tr:first-child {
			td { 
				border-top-style: solid;
			}

			td:first-child {
				border-top-left-radius: 10px; 
			}
			td:last-child {
				border-top-right-radius: 10px; 
			}
		}

		tr:last-child {
			td:first-child {
				border-bottom-left-radius: 10px;
			}
			td:last-child {
				border-bottom-right-radius: 10px;
			}
		}

		tr {
			&.clickable-row {
				cursor: pointer;

				&:hover {
					background-color: $hover-color;
				}
			}

			&.selected-row {
				background-color: $hover;
			}

			td:first-child { 
				border-left-style: solid; 
			}

			td:last-child { 
				border-right-style: solid; 
			}

			td {
				padding: 11px 15px;
				vertical-align: middle;

				border: solid 1px $neutral-1;
				border-style: none none solid none;
				padding: 10px;
			}
		}
	}

	.tejik-table-cell-shrink {
		width: 0;
	}
}

