@import 'styles/_variables.scss';

.tejik-form-row {
	display: flex;
	flex-direction: row;

	> .tejik-form-item  { 
		justify-content: center;
		margin-right: 12px;
		flex-grow: 1;

		&:last-of-type {
			margin-right: 0;
		}
	}
}

.tejik-form-item {
	display: flex;
	flex-direction: column;
	padding: 12px 0;

	> label {
		margin-bottom: 12px;
		color: $neutral-6;
	}

	&.tejik-form-item-row {
		flex-direction: row;
		align-items: center;

		> label {
			margin-right: 12px;
			margin-bottom: 0px;
		}
	}
}

.tejik-form {
	padding: 0 16px 16px 16px;
}