@import 'styles/_variables.scss';

.tejik-schedule-picker {
	display: flex;
	flex-direction: row;

	@media (max-width: 790px) {
		flex-direction: column;
	}

	.tejik-core-schedule {
		display: flex;
		flex-direction: row;
	}

	.tejik-preffix {
		align-self: center;
		margin-right: 1em;
		font-size: 14px;
		color: $neutral-3;
	}

	.tejik-popover {
		flex-grow: 1;
	}

	.tejik-select .tejik-menu-item {
		min-width: auto;
	}
}

.primary-share-label {
	width: fit-content;
	white-space: nowrap;
	margin-right: 1em;

	@media (max-width: 790px) {
		margin-left: 1em;
	}
}

.primary-share-label-sub {
	width: fit-content;
	white-space: nowrap;
	margin-left: 1em;
	margin-right: 1em;

	@media (max-width: 790px) {
		margin-left: 0;
		margin-right: 1.5em;
	}
}

.primary-platform-number {
	margin-right: 1em;
	width: 100px;

	@media (max-width: 790px) {
		height: 42px;
		width: 70px;
	}
}

.primary-platform-consistency {
	max-width: 170px;
	margin-right: 1em;

	@media (max-width: 790px) {
		height: 42px;
		width: 150px;
	}
}

.primary-platform-consistency-menu {
	max-width: 170px;

	@media (max-width: 790px) {
		width: 150px;
	}
}

.primary-weekday-selector {
	margin-right: 1em;
	align-self: center;

	@media (max-width: 790px) {
		margin-right: 0;
		align-self: flex-end;
	}
}

.primary-platform-time {
	max-width: 180px;

	@media (max-width: 790px) {
		margin-left: 1em;
		height: 42px;
		width: 150px;
	}
}

.primary-platform-time-menu {
	max-width: 180px;

	@media (max-width: 790px) {
		width: 150px;
		margin-left: 1em;
	}
}

.tejik-item-row {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;

	@media (max-width: 790px) {
		margin-bottom: 1em;
	}
}

.tejik-item-row-secondary {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

.tejik-item-row-social {
	display: flex;
	flex-direction: row;

	@media (max-width: 790px) {
		margin-bottom: 1em;
	}
}

.primary-platform-icon {
	align-self: center;	
	margin-left: 1.5em;

	@media (max-width: 790px) {
		margin: 0px;
	}
}

.primary-platform-name {
	align-self: center;	
	margin-left: 1em;

	@media (max-width: 790px) {
		margin: 0px;
	}
}

.secondary-platform-frequency {
	max-width: 260px;

	@media (max-width: 790px) {
		margin-top: 0;
		margin-left: 1em;
		height: 42px;
		width: 220px;
	}
}

.secondary-platform-frequency-small {
	max-width: 100px;

	@media (max-width: 790px) {
		margin-top: 0;
		margin-left: 1em;
		height: 42px;
		width: 110px;
	}
}

.secondary-platform-frequency-menu {
	max-width: 260px;

	@media (max-width: 790px) {
		width: 220px;
		margin-left: 1em;
	}
}

.secondary-platform-time {
	max-width: 180px;

	@media (max-width: 790px) {
		margin-top: 1em;
		height: 42px;
		width: 140px;
	}
}

.secondary-platform-time-menu {
	max-width: 180px;

	@media (max-width: 790px) {
		width: 140px;
	}
}

.secondary-label-only {
	width: fit-content;
	white-space: nowrap;
	margin-right: 1em;

	@media (max-width: 790px) {
		margin-left: 1em;
		margin-top: 2em;
		margin-bottom: 1em;
	}
}

.social-platform-time {
	max-width: 180px;

	@media (max-width: 790px) {
		height: 42px;
		width: 150px;
	}
}

.social-platform-time-menu {
	max-width: 180px;

	@media (max-width: 790px) {
		width: 150px;
	}
}