@import 'styles/_variables.scss';

$colors: 
	"primary" $primary,
	"secondary" $secondary,
	"ternary" $ternary,
	"error" $error,
	"info" $info,
	"hover" $hover,
	"neutral-1" $neutral-1,
	"neutral-2" $neutral-2,
	"neutral-3" $neutral-3,
	"neutral-4" $neutral-4,
	"neutral-5" $neutral-5,
	"neutral-6" $neutral-6;

@each $name, $variable in $colors {
	.text-#{$name}{
		color: $variable!important;
	}

	.bg-#{$name}{
		background-color: $variable!important;
	}
}
