.tejik-keyword-research-table-footer {
	display: flex;
	justify-content: flex-end;
	padding: 1em 0;
}

.tejik-keyword-questions, .tejik-keyword-hashtags {
	display: flex;
	flex-direction: column;

	input {
		flex-grow: 1;
		margin-bottom: 0.5em;
	}
}