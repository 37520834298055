@import 'styles/_variables.scss';

.tejik-prevent-scrolling {
	overflow: hidden !important;
}

.tejik-modal {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.25);
	z-index: 100;
	overflow: hidden auto;
	padding: 16px;
	box-sizing: border-box;

	&.tejik-message-modal {
		background-color: transparent;
		pointer-events: none;

		.tejik-modal-content {
			position: fixed;
			top: 10px;
			left: 0;
			right: 0;
			margin: 0 auto;
			padding: 8px 32px;
			z-index: 101;

			h6 {
				flex-grow: 1;
				text-align: center;
				font-size: 16px;
			}

			.tejik-modal-actions {
				margin: 0;
			}
		}
	}

	.tejik-modal-content {
		position: relative;
		background-color: $neutral-0;
		max-width: calc(100vw - 32px);
		padding: 28px 32px;
		margin: 60px auto auto auto;
		border-radius: 8px;
		box-shadow: 0px 8px 28px 8px rgba(0, 0, 0, 0.15);
		box-sizing: border-box;

		@media (max-width: 790px) {
			padding: 16px 24px;
		}

		.tejik-modal-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 0.5em;
			margin-left: 0.75em;

			.tejik-modal-title {
				display: flex;
				align-items: center;
				padding-right: 0.5em;
			}

			.tejik-modal-title-svg {
				padding-right: 1em;
			}

			.tejik-svg-icon {
				padding-right: 8px !important;
			}

			.tejik-modal-header-actions {
				flex-grow: 1;
			}
		}

		.tejik-modal-close {
			position: absolute;
			right: 4px;
			top: 4px;

			svg {
				color: $neutral-4;
			}
		}

		.tejik-modal-body {
			padding-top: 16px;
			padding-bottom: 16px;
		}

		.tejik-modal-centered {
			text-align: center;
		}

		.tejik-modal-actions {
			display: flex;
			margin-top: 0.1em;
			margin-left: 1em;
			margin-right: 1em;

			&.tejik-modal-actions-center {
				justify-content: center;
			}

			&.tejik-modal-actions-end {
				justify-content: flex-end;
			}

			&.tejik-modal-actions-between {
				justify-content: space-between;
			}
		}
	}
}

// Transitions
.tejik-modal-transition-enter,
.tejik-modal-transition-appear {
	display: initial;
	opacity: 0.01;

	.tejik-message-modal > .tejik-modal-content {
		transform: translate(-50%, 10px);
	}

	.tejik-modal-content {
		transform: translateY(10px);
	}
}

.tejik-modal-transition-enter-active,
.tejik-modal-transition-appear-active {
	display: initial;
	opacity: 1;
	transition: opacity 300ms ease;

	.tejik-message-modal > .tejik-modal-content {
		transform: translate(-50%, 0px);
	}

	.tejik-modal-content {
		transform: translateY(0px);
	}
}

.tejik-modal-transition-enter-done,
.tejik-modal-transition-appear-done {
	display: initial;
}

.tejik-modal-transition-exit {
	display: initial;
	opacity: 1;
}

.tejik-modal-transition-exit-active {
	display: initial;
	opacity: 0.01;
	transition: opacity 200ms ease;

	.tejik-message-modal > .tejik-modal-content {
		transform: translate(-50%, -10px);
	}

	.tejik-modal-content {
		transform: translateY(-10px);
	}
}
