@import 'styles/_variables.scss';

.sidemenu {
	display: flex;
	flex-direction: column;
	box-shadow: 5px 0 5px -2px $hover;
	position: absolute;
	justify-content: space-between;
	top: 0px;
	padding: 22px;
	width: 100%;
	height: 100%;
	max-width: 250px;

	.logo-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1em;
		margin-left: 1em;
	}

	.logo {
		justify-self: center;
		width: 75px;
		margin-top: 10px;
		margin-left: -2em;
	}

	.calendar-date-range {
		margin-top: 1.5em;
		margin-left: 0.5em;
	}

	.campaign-link {
		display: flex;
		margin-top: 1em;
		align-items: center;
	}

	.content-link-url-input {
		background-color: $neutral-0;
		color: $neutral-3;
		font-size: 14px;

		&:focus {
			color: $neutral-6;
		}
	}

	@media (max-width: 790px) {
		z-index: 1;
		position: fixed;
		left: -100%;
		background-color: $neutral-0;
		height: 100%;
		transition: left 200ms ease-in-out;

		&.show-sidemenu {
			left: 0;
		}
	}
}