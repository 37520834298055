@import 'styles/_typography.scss';

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	font-weight: 400;
}

$valid-elements: "h1", "h2", "h3", "h4", "h5", "h6";
$valid-weights: 400, 600, 700;

@mixin text-styles($size, $line-height, $letter-spacing) {
	font-size: $size;
	line-height: $line-height;
	letter-spacing: $letter-spacing;
}

@each $element, $values in $typography {
	@if index($valid-elements, $element) {
		#{$element} {
			@include text-styles(nth($values, 1), nth($values, 2), nth($values, 3));
		}
	}
}

@each $weight in $valid-weights {
	.font-weight-#{$weight} { 
		font-weight: $weight;
	}
}