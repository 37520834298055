.tejik-linked-ideas {
	display: flex;
	flex-wrap: wrap;

	.tejik-linked-idea {
		display: flex;
		font-size: 14px;
		line-height: 21px;
		padding: 4px 8px;
		border: 1px solid $neutral-1;
		border-radius: 10px;
		margin-right: 0.5em;
		margin-bottom: 0.5em;
		align-items: center;
		position: relative;
		text-decoration: none;

		> span {
			margin-left: 1em;
		}

		&:hover .unlink-button {
			display: flex;
		}

		.unlink-button {
			display: none;
			align-items: center;
			justify-content: space-evenly;
			width: 100%;
			padding: 4px 6px;
			border-radius: 10px;
			background-color: $hover;
			opacity: 0.9;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	
	.tejik-linked-ideas-new-button {
		padding: 0;
	}
}