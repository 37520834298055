@import 'styles/_variables.scss';

.tejik-checkbox {
	display: flex;
	align-items: center;
	min-height: 34px;
	
	> label {
		font-size: 16px;
		display: inline-flex;
    align-items: flex-start;

		> input {
			width: 18px;
			height: 18px;
			margin-left: 0;
			margin-right: 12px;
			accent-color: $ternary;
		}
	
		> input[type="checkbox"] {
			appearance: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			position: absolute;
			opacity: 0;
		}
	
		.circular-checkbox {
			display: inline-block;
			top: 2px;
			margin-right: 0.5em;
			min-width: 16px;
			width: 16px;
			height: 16px;
			border: 1.5px solid #000;
			border-radius: 50%;
			cursor: pointer;
			position: relative;
			transition: border-color 0.2s ease;
		}
	
		> input[type="checkbox"]:checked + .circular-checkbox {
			background-color: #fff;
			border-color: #000;
		}
	
		.circular-checkbox::before {
			content: "✔"; 
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 12px; 
			color: #000;
			transform: translate(-50%, -50%) scale(0); 
			transition: transform 0.2s ease;
			transform-origin: center;
		}
	
		> input[type="checkbox"]:checked + .circular-checkbox::before {
			transform: translate(-50%, -50%) scale(1);
		}
	}

	&.tejik-checkbox-small {
		> input {
			width: 16px;
			height: 16px;
		}
	}

	.tejik-checkbox-icon {
		margin-left: 0.5em;
	}

	.stroked-text {
		text-decoration: line-through;
		text-decoration-thickness: 2px;
	}
}