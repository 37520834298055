@import 'styles/_variables.scss';

.topbar {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: 18px 18px;

	.logo {
		width: 75px;
	}

	.help-top-button {
		margin-top: 6px;
		margin-right: 15px;
		margin-left: 15px;
		cursor: pointer;
		border: 1.5px solid black;
		border-radius: 30px;
		padding: 1px;
		padding-top: 2px;
		padding-right: 2px;

		@media (max-width: 790px) {
			margin-right: 1em;
			margin-left: 1em;
			padding: 1px;
		}
	}
}

.organizations-list {
	display: flex;
	flex-direction: column;

	.organization-item {
		display: flex;
		border: 1px solid $neutral-2;
		padding: 1em;
		margin-bottom: 1em;
		cursor: pointer;
		border-radius: 10px;

		&.active, &:hover {
			background-color: #FAFAFA;
		}

		> :first-child {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}

		.organization-item-actions {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

.campaign-platform-path {
	position: absolute;
	margin-top: 0.5em;
	margin-left: 23em;
	font-size: 12px;
	color: $dark-gray;
}