@import 'styles/_variables.scss';

$columns: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

.tejik-container {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: 0px 38px 38px 38px;

	@media (max-width: 790px) {
		padding: 0px 24px 24px 24px;
	}

	&.tejik-container-no-padding {
		padding: 0;
	}

	&.tejik-container-fill-height {
		height: 100%;

		@media (max-width: 790px) {
			height: auto;
		}

		> .tejik-container-body {
			height: 100%;

			@media (max-width: 790px) {
				height: auto;
			}
		}
	}

	.tejik-container-title {
		flex-direction: row;

		> .tejik-container-header-icon {
			margin-right: 0.75em;
		}

		> .tejik-container-title-div {
			@media (max-width: 790px) {
				margin-left: 0.5em ;
			}
		}
	}
	
	.tejik-container-header {
		display: flex;
		align-items: center;
		margin-bottom: 16px;
		
		.tejik-container-header-actions {
			display: flex;
    	justify-content: flex-end;
			flex-grow: 1;

			> * ~ * {
				margin-left: 12px;
			}
		}
	}

	.tejik-container-body {
		display: grid;
		grid-gap: 24px;
		grid-template-columns: repeat(12, 1fr);
		
		> *:not(.tejik-container-column) {
			grid-column: auto / span 12;

			@media (max-width: 790px) {
				grid-column: auto / span 4;
			}
		}

		@each $column in $columns {
			.span-#{$column} {
				grid-column: auto / span $column;
			}
		}
	
		@media (max-width: 1160px) {
			grid-template-columns: repeat(8, 1fr);
	
			@each $column in $columns {
				.span-#{$column} {
					grid-column: auto / span calc(min($column, 8));
				}
			}
		}
		
		@media (max-width: 790px) {
			grid-template-columns: repeat(4, 1fr);
	
			@each $column in $columns {
				.span-#{$column} {
					grid-column: auto / span calc(min($column, 4));
				}
			}
		}
	}
}