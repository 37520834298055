@import 'styles/_variables.scss';

.tejik-login-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	.tejik-login {
		width: 340px;

		.tejik-login-text {
			margin-bottom: 1rem;
		}

		svg#tejik-logo {
			display: flex;
			margin: 2rem auto;
			height: 100px;
		}

		.tejik-forgot-password {
			margin: 2rem 0;
			text-align: center;
		}

		.tejik-terms-and-conditions {
			color: $neutral-3;
			text-align: center;
			font-size: 14px;
		}
	}
}
