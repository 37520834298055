@import 'styles/_variables.scss';

$spinner-size: 1rem;
$spinner-gap: 1;
$spinner-wrapper: calc($spinner-size * 3 + $spinner-gap * $spinner-size * 2);
$spinner-color: $primary;
$spacer: calc($spinner-size * $spinner-gap + $spinner-size);

$animation-duration: 500ms;
$animation-count: infinite;

.tejik-loader {
	position: relative;
	width: $spinner-wrapper;
	height: $spinner-size;

	&.tejik-loader-centered {
		margin: 4rem auto;
	}
}

.tejik-loader span {
	position: absolute;
	top: 0;
	width: $spinner-size;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	background: $spinner-color;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.tejik-loader span:nth-child(1) {
	left: 0;
	animation: scale-up $animation-duration $animation-count;
}

.tejik-loader span:nth-child(2) {
	left: 0;
	animation: move-right $animation-duration $animation-count;
}

.tejik-loader span:nth-child(3) {
	left: calc($spacer * 1);
	animation: move-right $animation-duration $animation-count;
}

.tejik-loader span:nth-child(4) {
	left: calc($spacer * 2);
	animation: scale-down $animation-duration $animation-count;
}

@keyframes scale-up {
	from {
		transform: scale(0);
	}
	to {
		transform: scale(1);
	}
}

@keyframes scale-down {
	from {
		transform: scale(1);
	}
	to {
		transform: scale(0);
	}
}

@keyframes move-right {
	from {
		transform: translate(0, 0);
	}
	to {
		transform: translate($spacer, 0);
	}
}
