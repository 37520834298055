@import 'styles/_variables.scss';

.calendar-container.tejik-container-body {
  gap: 0 !important;
  grid-template-rows: auto 1fr;
  min-height: 300px;

  @media (max-width: 768px) {
    height: auto !important;
    min-height: auto;
  }

  .calendar-day-name {
    padding-bottom: 0.5em;
    align-self: center;
  }

  .calendar-day {
    position: relative;
    border-left: 1px solid $neutral-2;
    border-top: 1px solid $neutral-2;
    min-height: 28px;

    &.first-day-month {
      border-top-left-radius: 10px;
    }

    &.last-week-day {
      border-top-right-radius: 10px;
    }

    &.last-week {
      border-bottom-left-radius: 10px;
    }

    &.last-week-of-month {
      border-bottom: 1px solid $neutral-2;
    }

    &.last-month-day {
      border-bottom-right-radius: 10px;
    }

    &.week-end {
      border-right: 1px solid $neutral-2;
    }

    &.not-this-month {
      background-color: $hover;
    }

    &.selected {
      border: 2px solid $neutral-6;

      @media (max-width: 768px) {
        border: 2px solid $neutral-6;
      }
    }

    &.idea-over {
      background: $secondary;
    }

    > .calendar-date {
      position: absolute;
      top: 18px;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      font-size: 14px;
      color: $neutral-4;

      &.not-current-month {
        opacity: 0.8;
      }
    }
  }

  .calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(6, 1fr);

    @media (max-width: 768px) {
      .calendar-day {
        height: calc(100vw / 7);
      }
    }
  }

  .calendar-header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
	  text-align: center;
    border: none;

    > .calendar-day {
      padding: 0.25em 0.5em;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
	    line-height: 18px;
      border-right: none;
    }
  }

  .events-container {
    .event-color-dots {
      display: none;
    }

    @media (max-width: 790px) {
      padding-top: 1em;
      height: 100%;

      .event-color-dots {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 1em;
      }

      .event-color-dot {
        display: flex;
        width: 12px;
        height: 12px;
        border-radius: 12px;
        background-color: $neutral-1;
        align-items: center;
        font-size: 18px;
        justify-content: center;
      }
    }

    .event-item-container {
      display: flex;
      flex-direction: row;

      &:first-of-type {
        margin-top: 36px;
      }

      .event-item-status {
        min-width: 5px;
      }

      .event-item {
        border: 2px solid $neutral-2;
        border-radius: 10px;
        padding: 4px 8px;
				margin: 0px 2px 2px 2px;
        flex-grow: 1;

        .event-link {
          text-decoration: unset !important;
          cursor: pointer;
        }
      }
    }
  }
}

.day-name {
	font-weight: bold;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	padding-right: 1em;
	padding-left: 1em;
	padding-bottom: 1em;
}

.footer-item-container {
  display: flex;
  align-items: center;
  flex-direction: column;

  .footer-item {
    width: 100%;
    border: 2px solid $neutral-2;
    border-radius: 10px;
    min-height: 65px;
		margin-bottom: 2px;
    padding: 8px;
  }
}