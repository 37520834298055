@import 'styles/_variables.scss';

.tejik-card {
	display: grid;
	grid-template-columns: 44px 1fr 30px;
    align-items: center;
	padding: 2em 2em;
    border: 1px solid $neutral-2;
	border-radius: 10px;
	text-decoration: none;

	&.clickable {
		cursor: pointer;
	}
}