.create-idea-form {
	@media (max-width: 790px) {
		margin-left: 0.25em;
	}
}

.idea-container-header {
	margin-top: 1em;
	margin-left: 1em;

	@media (max-width: 790px) {
		margin-left: 0;
	}
}

.idea-form-item {
	margin-top: -1.5em;
}

.idea-platforms {
	flex-grow: 1;
	margin-top: 1em;

	.idea-form-item {
		margin-top: -1em;
		align-items: center;
	}
}

.create-idea-form {
	.idea-form-item {
		margin-top: -1em;

		@media (max-width: 790px) {
			margin-top: 0;
		}
	}
}

.idea-linked-ideas {
	margin-top: 1.5em;
	width: 220px;
	align-items: center;

	@media (max-width: 790px) {
		margin-top: 0.5em;
		width: 100%;
	}
}

.sponsored-idea-platforms.tejik-platform-selection {
	margin-left: 1em;
	padding: 0;
	
	.tejik-platform-selection-button {
		font-size: 16px;
	}
}