@import 'styles/_variables.scss';

.new-social-post-modal {
	.tejik-modal-header {
		align-items: center;
	}
	
	.tejik-modal-header-actions > .tejik-platform-selection {
		justify-content: flex-end;
	}
}

.social-post-categories {
	display: flex;
	overflow-x: auto;

	.category-card {
		min-width: 200px;
		margin-right: 1rem;
		height: auto;
		text-align: center;

		&.active {
			background-color: $neutral-1;
		}
	}
}

.social-posts-list {
	.social-post-title {
		display: flex;
		align-items: center;

		.social-post-due-date {
			display: flex;
			padding-right: 1rem;
		}
	}

	.social-post-not-configured {
		display: flex;
		background-color: $secondary;
		padding-right: 1rem;
	}

	.social-post-row-configured {
		background-color: $secondary;
	}

	.social-post-actions {
		padding: 0 24px;
		display: flex;
		align-items: center;
		border-left: 1px solid $neutral-1;
	}

	.social-post-platforms {
		min-width: 100px;
		margin-left: 1em;
	}

	.social-post-actions-type {
		width: 200px;
		justify-content: center;
	}

	.social-post-actions-category {
		width: 175px;
		justify-content: center;
	}
}

.social-platform-label {
	display: flex;
	align-items: center;
	justify-content: space-between;

	> div {
		margin-top: 1em;

		> span {
			margin-left: 0.5em;
		}
	}
}

.hashtag-options {
	margin-top: -1.5em;
	margin-bottom: 2em;
	
	> .tejik-popover {
		width: 100%;
	}
}

.tejik-social-widget-status {
	width: fit-content;
	padding: 0.5em;
	font-size: 14px;
	border-radius: 10px;
}

.social-character-limit-text {
	text-align: right;
	padding-top: 0.5em;
	padding-bottom: 1em;
	font-size: 12px;	
	color: $neutral-2;
}

.campaign-social-delete-link {
	display: flex;
	align-items: center;
	margin-left: auto;
	cursor: pointer;
}