@import 'styles/_variables.scss';

.keyword-research-button {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: 0.5rem;
	
	> .permalink {
		font-size: 12px;	
		color: $neutral-2;
	}
	
	> .tejik-button {
		float: right;
		z-index: 1;
	}
}

.platform-scrollbar {
	width: '100%';
	overflow-x: auto;
	overflow-y: hidden;
	white-space: nowrap;
	display: flex;
	margin-top: 0.5em;
}

.platform-scrollbar::-webkit-scrollbar {
  height: 4px; 
}

.platform-scrollbar::-webkit-scrollbar-thumb {
  background-color: #355070; 
  border-radius: 5px; 
}

.platform-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #355070; 
}

.platform-scrollbar::-webkit-scrollbar-track {
  background-color: $neutral-1;
}

.campaign-keyword-research {
	display: flex;
	margin-bottom: 1.5em;
	align-items: center;
	justify-content: space-between;
}