@import 'styles/_variables.scss';

.recycle-content-table {
	.platform-container {
		&.with-days {
			grid-template-columns: 32px 100px 4fr 1fr;
		}

		display: grid;
		grid-template-columns: 32px 100px 1fr;
		align-items: center;
		font-size: 16px;

		> .material-icons-outlined, > svg {
			margin-right: 0.5em;
		}

		input.small {
			padding: 10px;
			border-left: 0;
		}
	}
}

.recycle-platform-select-status {
	display: grid;
	grid-template-columns: 32px auto 1fr;
	align-items: center;
	width: 200px;
	padding: 1em;
	border-radius: 10px;
	border: 1px solid $neutral-1;

	@media (max-width: 790px) {
		align-self: flex-start;
		margin-left: 0.75em;
		width: 300px;
	}
}

.recycle-platform-select-empty {
	align-items: center;
	width: 200px;
	padding: 1em;
	border-radius: 10px;
	border: 1px solid $neutral-1;

	@media (max-width: 790px) {
		align-self: flex-start;
		margin-left: 0.75em;
		width: 300px;
	}
}

.tejik-preffix {
	align-self: center;
	font-size: 14px;
	margin-right: 1em;
	color: $neutral-3;
	width: fit-content;
	overflow: hidden;
	white-space: 'nowrap';

	@media (max-width: 790px) {
		min-width: 60px;
	}
}

.recycle-select-status-line {
	display: flex;
	flex-direction: row;

	@media (max-width: 790px) {
		flex-direction: column;
		justify-content: flex-start;
	}
}

.recycle-days {
	margin-right: 1em;
	width: 130px;

	@media (max-width: 790px) {
		width: 100px;
	}
}

.recycle-days-message {
	width: fit-content;
	overflow: hidden;
	white-space: 'nowrap';
	margin-right: 1em;
	font-size: 14;
	line-height: normal;

	@media (max-width: 790px) {
		margin: 1em;
	}
}
