@import 'styles/_variables.scss';

.tejik-password-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;

	.tejik-password {
		width: 300px;

		svg#tejik-logo {
			display: flex;
			margin: 2rem auto;
			height: 100px;
		}

		.tejik-already-member {
			margin: 2rem 0;
			text-align: center;
		}
	}
}
