@import 'styles/_variables.scss';

.planner-container.tejik-container-body {
  grid-template-rows: auto 1fr;
  gap: 0 !important;

  .planner-calendar-container-loading {
    justify-items: center;
  }
}

.planner-calendar-container {
	margin-left: 0.3em;
	margin-right: 0.3em;

	@media (max-width: 790px) {
		margin-left: 1em;
		margin-right: 1em;
	}
}

.calendar-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

	@media (max-width: 790px) {
	  justify-content: space-evenly;
  }

  .calendar-filters {
    gap: 16px;
  }

  > div {
    display: flex;
    align-items: center;
    width: 100%;

    > button.tejik-button {
      padding: 4px;
      font-size: 14px;
    }
  }

  .calendar-month {
    justify-content: center;
    font-size: 24px;

    .tejik-button ~ .tejik-button {
      margin-left: 0;
    }

    .calendar-month-name {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      padding: 0 16px;
    }
  }

  .calendar-ideas {
    justify-content: flex-end;

    > .tejik-input-icon-wrapper {
      margin-right: 12px;
    }

    > button.tejik-button {
      display: flex;
    }
  }
}

.calendar-month-mobile {
  display: none;

	@media (max-width: 790px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    margin-bottom: 0.5em;
  }

  .tejik-button ~ .tejik-button {
    margin-left: 0;
  }

  .calendar-month-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0 16px;
  }
}

.today-tasks-view {
  display: flex;
  width: 100%;
  justify-self: center;

  .day-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    padding-bottom: 1em;
  }
}