@import 'styles/_variables.scss';

.sidebar {
	display: flex;
	flex-direction: column;
	box-shadow: 5px 0 5px -2px $hover;
	position: absolute;
	top: 0px;
	padding: 22px;
	width: 100%;
	height: 100%;
	max-width: 250px;

	.sidebar-spacer {
		flex-grow: 1;
	}

	.logo-container {
		display: flex;
		justify-content: center;
		margin-bottom: 1em;
	}

	.logo {
		justify-self: center;
		width: 75px;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.workplace-select {
		border: 1px solid $neutral-1;
		padding: 8px;
		margin: 16px -8px 0px -8px;
		border-radius: 10px;
	}

	@media (max-width: 790px) {
		z-index: 1;
		position: fixed;
		left: -100%;
		background-color: $neutral-0;
		height: 100%;
		transition: left 200ms ease-in-out;

		&.show-sidebar {
			left: 0;
		}
	}
}
