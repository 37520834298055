@import 'styles/_variables.scss';

.tejik-platform-widget {
	min-width: 300px;
	display: flex;
	flex-direction: column;
	border: 1px solid $neutral-1;
	border-radius: 10px;
	padding: 1em;

	&.selected {
		background-color: $hover;
	}
	
	.tejik-platform-widget-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.tejik-platform-widget-second {
		margin-top: 1em;
	}

	.tejik-platform-widget-footer {
		border-top: 1px solid $neutral-1;
		margin-top: 0.75em;
		margin-left: -1em;
		margin-right: -1em;
		padding-left: 1em;
		padding-top: 0.75em;
		margin-bottom: -0.25em;
	}

	.tejik-platform-widget-name {
		display: flex;
		align-items: center;
	}

	.tejik-platform-widget-status {
		width: fit-content;
		padding: 0.5em;
		margin-top: 0.75em;
		margin-bottom: -0.25em;
		border-radius: 10px;
	}

	.tejik-platform-widget-progress {
		width: 65px;
		height: 65px;
		margin-top: -0.25em;
	}

	.tejik-platform-widget-check {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.tejik-platform-widget-icon {
		color: $dark-gray;
		font-size: 14px;
		margin-right: 0.25em;
	}

	.tejik-platform-widget-text {
		color: $dark-gray;
		font-size: 14px;
	}

	.tejik-platform-widget-date {
		width: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-right: 1em;
		margin-top: 0.5em;
		color: $neutral-3;
	}

	.tejik-platform-widget-status-modal {
		position: fixed;
		margin-top: 6px;
		padding: 1em;
		z-index: 999;

		background-color: $neutral-0;
		box-shadow: 0px 8px 28px 8px $modal;
		border-radius: 10px;
		color: $neutral-6;
		border: 1px solid $modal;
	}
}