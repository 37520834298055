@import 'styles/_variables.scss';

.hook-group-name {
	background-color: transparent;
}

.new-hook-input {
	@media (max-width: 790px) {
		width: 90%;
	}
}

.new-hook-select {
	@media (max-width: 790px) {
		width: 90%;
		margin-top: -1px;
		margin-left: 1em;
	}
}

.new-hook-menu {
	@media (max-width: 790px) {
		width: 90%;
		margin-top: -90%;
	}
}

.new-hook-button {
	margin: 0px;

	@media (max-width: 790px) {
		margin: inherit;
	}
}